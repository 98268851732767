<template>
  <div class="admin">
    <div :class="'login'" v-show="!token_verify">
      <div class="login_box" v-show="!is_log">
        <div class="login_top_box">
          <div>登录</div>
        </div>
        <div class="login_bottom_box">
          <div class="form_box">
            <div class="input_item">
              <span class="label_input">用户名:</span>
              <input class="input_box" v-model="username" type="text" name="username" placeholder="请输入用户名">
            </div>
            <div class="input_item">
              <span class="label_input">密&nbsp;&nbsp;码:</span>
              <input class="input_box" v-model="password" type="password" maxlength="16" name="password"
                     placeholder="请输入密码">
            </div>
            <div class="login_button_box">
              <button class="login_button" @click="login">登录</button>
              <button class="login_button" @click="token_verify=true;">游客</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="manage_page">
      <div class="top_bar">
        <div :class="['top_bar_item',component==='Article_manage'?'top_bar_item_active':'']"
             @click="change_content('Article_manage')">文章管理
        </div>
        <div :class="['top_bar_item',component==='Music_manage'?'top_bar_item_active':'']"
             @click="change_content('Music_manage')">音乐管理
        </div>
        <div :class="['top_bar_item',component==='System_data'?'top_bar_item_active':'']"
             @click="change_content('System_data')">系统状态
        </div>
        <div class="top_bar_item user_log" style="cursor: pointer" @click="show_login">{{ user_message }}</div>
      </div>
      <div class="page_content">
        <keep-alive exclude="article_edit">
          <component :is="component" :key="component"></component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";

const Music_manage = defineAsyncComponent(() => import("@/components/manage/Music_manage"));
const Article_manage = defineAsyncComponent(() => import("@/components/manage/Article_manage"));
const System_data = defineAsyncComponent(() => import("@/components/manage/System_data"))

export default {
  name: "admin",
  components: {
    Music_manage,
    Article_manage,
    System_data
  },
  data() {
    return {
      // component: 'Article_manage',
      component: 'Article_manage',
      username: '',
      password: '',
      is_log: true,
      token_verify: false,
      user_message: '登录'
    }
  },
  methods: {
    login() {
      this.$ElNotification({
        title: '登陆中...',
        type: 'info'
      })
      let data = new FormData()
      data.append('username', this.username)
      data.append('password', this.password)
      this.$axios.post('/api/login', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$ElNotification({
            title: '登录成功',
            type: 'success'
          })
          this.token_verify=true
          this.user_message = '登出'
          this.username = ''
          this.password = ''
          localStorage.setItem('token', res.data.data.token.access)
          localStorage.setItem('refresh', res.data.data.token.refresh)
        } else if (res.data.code === 401) {
          this.$ElNotification({
            title: '登录失败',
            message: res.data.message,
            type: 'warning'
          })
        } else {
          this.$ElNotification({
            title: '登录失败',
            message: res.data.message,
            type: 'error'
          })
        }
      })
    },
    test() {
      this.$axios.get('/api/getInfo', {
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        this.$ElNotification({
          title: '返回信息',
          message: res.data.message,
          type: 'info'
        })
      })
    },
    show_login() {
      this.token_verify = false
      this.is_log = false
      this.user_message = '登录'
      let token = localStorage.getItem('token')
      if (token) {
        localStorage.removeItem('token')
      }
      let refresh = localStorage.getItem('refresh')
      if (refresh) {
        localStorage.removeItem('refresh')
      }
    },
    tokenVerify() {
      let access_token = localStorage.getItem('token')
      if (access_token) {
        this.$axios.post('/api/verify', {
          token: access_token
        }).then(() => {
          this.token_verify = true
          this.user_message = '登出'
        }).catch(() => {
          this.token_verify = false
          this.refreshToken()
        })
      } else this.is_log = false
    },
    async refreshToken() {
      let state = null
      try {
        let refresh = localStorage.getItem('refresh')
        if (refresh) {
          let res = await this.$axios.post('/api/refresh', {
            'refresh': localStorage.getItem('refresh')
          })
          if (res.data.access) {
            localStorage.setItem('token', res.data.access)
            this.user_message = '登出'
            state = true
          } else state = false
        } else state = false
      } catch (reason) {
        // console.log(reason)
        state = false
      }
      if (!state) {
        this.is_log = false
        this.token_verify = false
      } else {
        this.token_verify = true
        this.is_log = true
      }
    },
    change_content(page) {
      this.component = page
    }
  },
  created() {
    this.tokenVerify()
    // this.refreshToken()
    // this.$axios.interceptors.request.use(config => {
    //   config.headers.Authorization = 'JWT ' + localStorage.getItem('token')
    //   console.log(config)
    //   return config
    // }, error => {
    //   console.log('错误')
    //   throw error
    // })
    this.$axios.interceptors.response.use(response => response, error => {
      let res_text = '错误'
      if (error.response.status === 401) {
        res_text = '权限验证未通过'
      }
      this.$ElNotification({
        title: res_text,
        type: 'info'
      })
    })
    this.$axios.interceptors.request.use(config => {

      return config
    })
  },
  mounted() {

  }
}
</script>

<style scoped>
.admin {
  width: 100%;
  height: 100%;
  position: relative;
}

.login {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 100;
  background-color: #17cafb;
  background-image: url("https://cloud.dboy9527.top/images/26f2c90ca242e518735ebe752383d54b.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login_box {
  width: 500px;
  height: 550px;
  border-radius: 15px;
  overflow: hidden;
  margin: auto;
}

.login_top_box {
  height: 150px;
  width: 100%;
  background-color: #2c3e50;
  background-image: url("https://cloud.dboy9527.top/images/83ea043c71e01bb45b8db802eafaca7f.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login_top_box > div {
  color: #8a8a8a;
  text-align: center;
  line-height: 50px;
  font-size: 30px;
  padding-block: 50px;
}

.login_bottom_box {
  width: 100%;
  height: 400px;
  background-color: #cdd9d9;
  display: flex;
}

.form_box {
  margin: auto;
}

.input_item {
  padding-block: 20px;
}

.label_input {
  display: inline-block;
  width: 60px;
  text-align-last: justify;
}

.input_box {
  border: none;
  outline: none;
  height: 30px;
  width: 180px;
  margin-inline: 5px;
  border-bottom: 1px solid #2d2d2d;
  background-color: #00000000;
}

.login_button_box {
  text-align: center;
  margin-block: 20px;
}

.login_button {
  border: none;
  height: 40px;
  width: 100px;
  border-radius: 20px;
  font-size: 15px;
  margin-inline: 5px;
}


.icon-rotation {
  display: inline-block;
  /*line-height: 30px;*/
  /*animation: rotation 2s linear infinite;*/
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.manage_page {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 15px;
}

.top_bar {
  width: 100%;
}

.top_bar_item {
  display: inline-block;
  width: 200px;
  height: 40px;
  border-radius: 15px 15px 0 0;
  margin-right: 10px;
  text-align: center;
  line-height: 40px;
  background-color: #a19f9d;
  cursor: pointer
}

.top_bar_item_active {
  background-color: #e1dfdd;
}

.user_log {
  float: right;
  width: 100px;
  margin-right: 20px;
  background-color: #e1dfdd;
}

.page_content {
  background-color: #e1dfdd;
  width: 100%;
  height: calc(100% - 40px);
  border-radius: 0 15px 15px 15px;
  overflow: hidden;
}

</style>